<!-- Render Layouts -->
<!-- <router-outlet></router-outlet> -->

<!-- Header -->
<app-header *ngIf="showHeader"></app-header>
<div class="main-content">
  <router-outlet></router-outlet>

  <!-- <app-footer *ngIf="!mobilePages" [ngClass]="{'map_foottog': map_togfooter}"></app-footer> -->
  <!-- <app-footer *ngIf="isProjectPage"></app-footer> -->
  <app-footer></app-footer>
</div>
