import { Component, OnInit, Injectable,ChangeDetectorRef,ViewChildren,HostListener, ViewChild, ElementRef } from '@angular/core';
import { AuthenticationService, SEOService, AppSettings, HelperService, PurposeService, FavoriteService, HideService, FacebookLoginService, GoogleLoginService } from '../../../services/_services';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

// Home Page
// import { HomeComponent } from '../../pages/home/home.component';
// Social Login
// import { AuthService } from "angularx-social-login";

// Bootsteap Components
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

// Modal Components
// import { LoginComponent } from '../../entry-components/login/login.component';
// import { SiginUpOptsComponent } from '../../entry-components/sigin-up-opts/sigin-up-opts.component';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
// import { SiginUpComponent } from '../../entry-components/sigin-up/sigin-up.component';
import { ConvertCurrencyPipe } from '../../../services/pipes/convertCurrency/convert-currency.pipe';
// Sweet Alerts
import Swal from 'sweetalert2';
declare var $;

@Injectable({
  providedIn: 'root',
})

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {


  public subscription             : any = { email: ''};
  public subscriptionForm         : FormGroup;
  public submitted                : any = false;
  public newsLetterLoader         : any = false;
  public newsLetterSuccess        : any = false;
  public lpModal                  : any = false;

  public modalRef           : BsModalRef;
  public isUserLoggedIn     : any = false;
  public loading            : any = false;
  public toggleSide         : any = false;
  public sideNav            : any = false;
  public user :any = {
    'first_name'            : '',
    'last_name'             : '',
    'email'                 : '',
    'password'              : '',
    'phone_number'          : '',
    'dob'                   : null,
  }
  public searchByID         : any;
  public activeRoute        : any = "";
  public searchingLoad      : any = false;
  public newDesign          : any = false;
  public atHomePage         : any = false;
  public atAddPage          : any = false;
  public projectNewPage     : any = false;
  public atlisting          : any = false;
  public authSub            : any;
  public socail_AuthSub     : any;
  public propuseSub         : any;
  public Purpose            : any;
  public favoriteSub        : any;
  public favoritePropCount  : any = 0;
  public hiddenSub          : any;
  public hiddenPropCount    : any = 0;
  public activeTabCss       : any = 0;



  // Subscriptions
  public routerSubscription   : any;
  public ImagesUrl            : any = AppSettings.IMG_ENDPOINT;

  public lazyEntryCompsLoaded       : any = false;
  public LoginComponent             : any;
  public SiginUpComponent           : any;
  public SiginUpOptsComponent       : any;
  public ContactDetailsComponent    : any;
  public ProContactDetailsComponent : any;
  public InquiryModalComponent      : any;
  public investPages                : any = false;
  public investInner                : any = false;
  public headerSpacing              : any = false;
  public openedWebInfo              : any = '';
  public noHeader                   : any = false;
  public sellPages                  : any = false;
  public selectedTabSub             : any = '2';
  public selectedTabCount           : any = 0;
  public sub_activeTab              : any = '2';
  public initSidebar                : any = false;
  public scrollCheck                : any = false;
  public noFixed                    : any = true;
  public loginDropDown              : any = false;

  // Blog Vars
  public blogCategories             : any = [];
  public atBlogPage                 : any = false;
  public initblogSidebar            : any = false;

  @ViewChild('Product',               {static: false}) Product:       ElementRef;
  @ViewChild('Home',                  {static: false}) Home:          ElementRef;

  // close loginDropDown
  @ViewChild('logindDrpDownBtn',      {static: false}) logindDrpDownBtn:       ElementRef;
  @ViewChild('ddSubBtn',              {static: false}) ddSubBtn:          ElementRef;
  @HostListener('document:click', ['$event'])
  DocumentClick(event: Event) {
    if(this.noHeader == false){
      if (this.logindDrpDownBtn.nativeElement.contains(event.target) || this.ddSubBtn.nativeElement.contains(event.target))
        return;
      else
        this.loginDropDown = false;
    }
  }

  // fix header
  @HostListener('window:scroll', ['$event'])
  track(event: Event){
      if(window.pageYOffset > 80){
        this.scrollCheck = true;
      }
      else{
        this.scrollCheck = false;
      }
  }

  constructor(
    private modalService           : BsModalService,
    // private authService: AuthService,
    private authenticationService  : AuthenticationService,
    private facebookLoginService   : FacebookLoginService,
    private googleLoginService     : GoogleLoginService,
    private activatedRoute         : ActivatedRoute,
    private router                 : Router,
    private seoService             : SEOService,

    // private homePage               : HomeComponent,
    private helperService          : HelperService,
    private purposeService         : PurposeService,
    private favoriteService        : FavoriteService,
    private hideService            : HideService,
    private cdr                    : ChangeDetectorRef,
    private _location              : Location,
    private formBuilder            : FormBuilder,

  ) {
    this.selectedTabSub = this.helperService.selectedTabS$.subscribe((selectedTab) => {
      if(this.sub_activeTab != selectedTab)
      this.sub_activeTab = selectedTab;
    });

    // this.cdr.detectChanges();
    this.routerSubscription = this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        this.routeCheck(val);
      }
    });
    // this.routeCheck();
    // this.activeRoute = this.router.url;
    // console.log("this.activeRoute***",this.activeRoute);

    this.openedWebInfo = '1';
  }


  backClicked() {
   this._location.back();
  }

  routeCheck(val){
    // Defualt set to false
    this.activeRoute    = val.url;
    this.noHeader       = false;
    this.atHomePage     = false;
    this.atBlogPage     = false;
    this.sellPages      = false;
    this.atAddPage      = false;
    this.projectNewPage = false;
    this.investPages    = false;
    this.headerSpacing  = true;
    this.newDesign      = false;


    // if (typeof window != "undefined") {
    //   if (window.innerWidth < 1300 && window.innerWidth > 991) {
    //       $("html").css("zoom","0.8");
    //   }
    // }

    if (typeof $ != 'undefined') {
      if ( this.openedWebInfo == '1' && window.innerWidth <= 575 && !this.isUserLoggedIn && (this.activeRoute == "/" || this.activeRoute == "/invest")) {
        // this.lazyLoadEntryComponents();
        // console.log("this.activeRoute====", this.activeRoute);

        setTimeout(function() {
          $('.login-m.splash-m .modal-logo').addClass('animatedSplash-In');
          $('.animatedSplash .splashAnimate').addClass('animatedSplash-In');

        }, 1000);

        $("#splashScreen").modal("show");
        setTimeout(function() {
          $("#splashScreen").modal("hide");
          // $("#mobileFirstScreen").modal("show");

          // console.log("this.openedWebInfo***",self.openedWebInfo);
          $('.login-m.splash-m .modal-logo').removeClass('animatedSplash-In');
          $('.animatedSplash .splashAnimate').removeClass('animatedSplash-In');

        }, 2000);
        this.openedWebInfo = '0';
        // console.log("this.activeRoute====", this.activeRoute);
        // console.log("this.openedWebInfo===", this.openedWebInfo);
      }
    }

    // for launch product modal

    if(this.activeRoute.includes('/skyparkone') || this.activeRoute.includes('/karakoram-enterprises')){
      this.lpModal = false;
    }
    else{
      this.lpModal = false;
    }

    // for launch product modal

    if(this.activeRoute == '/' || this._location['_platformLocation'].pathname == '/index.php' ) {
      // keep it false until homepage is redirected
      this.atHomePage = true;
      this.newDesign  = true;

      // keep it false until homepage is redirected
      this.headerSpacing = false;
      this.setSEO_Tags('Buy, Rent, Sell, Homes & Real Estate Properties In Pakistan', 'Gharbaar.com, the leading real estate resource for property buyers, sellers, landlords, tenants and real estate agents in Pakistan. Real-time residential, commercial, and investment listing updates. Gharbaar.com is the easy-to-use online portal that takes the hassle and uncertainty out of buying, selling, renting, or owning.', true);
    }
    else if(this.activeRoute.indexOf('dashboard') !== -1) {
      this.noHeader = true;
    }
    else if(this.activeRoute.includes('/sale') || this.activeRoute.includes('/rent')){

      this.headerSpacing = false;
      // this.setSEO_Tags('Buy, Rent, Sell, Homes & Real Estate Properties In Pakistan', 'Gharbaar.com, the leading real estate resource for property buyers, sellers, landlords, tenants and real estate agents in Pakistan. Real-time residential, commercial, and investment listing updates. Gharbaar.com is the easy-to-use online portal that takes the hassle and uncertainty out of buying, selling, renting, or owning.', false);
    }
    else if(this.activeRoute == '/add') {
      this.atAddPage = true;

      this.headerSpacing = false;
      this.setSEO_Tags('Add new property on Gharbaar.com', 'Add new property on Gharbaar.com')
    }
    else if(this.activeRoute == '/find-pro') {
      this.atHomePage = true;

      this.headerSpacing = false;
      this.setSEO_Tags('Search Agents, Developers, Architects in Pakistan', 'Gharbaar.com is easy-to-use online portal that takes the hassle and uncertainty out of b uying, selling, renting, or owning. Real-time residential, commercial, and investment listing updates.', false);
    }
    else if(this.activeRoute == '/blueworldcity') {

      this.headerSpacing = false;
      this.setSEO_Tags('Blue World City Islamabad | Gharbaar.com', "All about Blue World City Islamabad. Project Details, NOC, Location, Map, Prices and more at Pakistan's best property portal | Gharbaar.com", false);
    }
    else if(this.activeRoute == '/blue-world-city') {
    //
      this.headerSpacing = false;
      this.setSEO_Tags('Blue World City Islamabad | Plots For Sale | Gharbaar.com', "All about Blue World City Islamabad. Project Details, NOC, Location, Map, Prices and more at Pakistan's best property portal | Gharbaar.com", false);

    }
    else if(this.activeRoute == '/capital-smart-city') {
    //
      this.headerSpacing = false;
      this.setSEO_Tags('Capital Smart City Islamabad | Plots For Sale | Gharbaar.com', "All about Capital Smart City. Project Details, NOC, Location, Map, Prices and more at Pakistan's #1 home buying resource. Gharbaar.com", false);

    }
    else if(this.activeRoute == '/blue-world-city/overseas-block') {
    //
      this.headerSpacing = false;
      this.setSEO_Tags('Blue World City Islamabad Overseas Block | Gharbaar.com', "All about Blue World City Islamabad Overseas Block | Gharbaar.com", false);
    }
    else if(this.activeRoute == '/blue-world-city/hot-offer') {

      this.headerSpacing = false;
      this.setSEO_Tags('Blue World City Islamabad Hot Offer | Gharbaar.com', "Blue World City Islamabad Hot Offer. | Gharbaar.com", true);
    }
    else if(this.activeRoute == '/karakoram-enterprises') {
      this.projectNewPage = true;
      this.headerSpacing = false;
      this.noHeader = true;
      this.setSEO_Tags('Buy Luxury Apartments in Karakoram Greens – Direct from Owner, No Commission!', "Own a ready-to-move-in luxury apartment in Karakoram Greens, Gulberg Islamabad with no middleman & no extra fees. Earn 1-1.5 lac/month rental income, enjoy 100+ unmatched amenities, and live among elite residents. Call now: +92 315 5182679.", false);
    }
    else if(this.activeRoute == '/skyparkone') {
      this.projectNewPage = true;
      this.headerSpacing = false;
      this.noHeader = true;
      this.setSEO_Tags('Skypark One Gulberg Islamabad Development Project | Gharbaar.com', "Exclusive luxury living in Gulberg Greens | Elegant residential and commercial units | Early Bird Pricing and more at Islamabad's best property portal Gharbaar.com", false);
    }
    else if(this.activeRoute == '/skyparkone/payment-plan'){
      this.projectNewPage = true;
      this.headerSpacing = false;
      // this.noHeader = true;
      this.setSEO_Tags('Eight pricing plans | Eight housing and commercial levels | Best Pricing ', 'A wide selection of available units with a full range of pricing. Select your unit today for Early Bird Pricing.', false);
    }

    else if(this.activeRoute == '/skyparkone/residential-prices'){
      this.projectNewPage = true;
      this.headerSpacing = false;
      this.noHeader = true;
      this.setSEO_Tags('Choose your Unit - Residential Apartments', "Choose your Residential Apartments. Skypark One, a commercial & residential mixed mall.", false);
    }
    else if(this.activeRoute == '/skyparkone/foodcourt-prices'){
      this.projectNewPage = true;
      this.headerSpacing = false;
      this.noHeader = true;
      this.setSEO_Tags('Choose your Unit - Food Court Units', "Choose your Food Court Units. Skypark One, a commercial & residential mixed mall.", false);
    }
    else if(this.activeRoute == '/skyparkone/retailshops-prices'){
      this.projectNewPage = true;
      this.headerSpacing = false;
      this.noHeader = true;
      this.setSEO_Tags('Choose your Unit - Retail Shops', "Choose your Retail Shops. Skypark One, a commercial & residential mixed mall.", false);
    }

    else if(this.activeRoute.indexOf('unit-detail') != -1){
      this.projectNewPage = true;
      this.headerSpacing = false;
      this.noHeader = true;
      this.setSEO_Tags('Unit details - Virtual tour | Unit Blueprints', "Skypark One unit details with virtual tour and unit blueprints. A commercial & residential mixed mall. Start your dream.", false);
    }

    else if(this.activeRoute == '/skyparkone/schedule-visit'){
      this.projectNewPage = true;
      this.headerSpacing = false;
      this.noHeader = true;
      this.setSEO_Tags('Schedule a Free Consultation with an Expert | Skypark One', "Let the brains of the industry guide you to the most profitable investment opportunity. Schedule a session today for a detailed visit to the construction site!", false);
    }

    else if(this.activeRoute == '/about-us') {

      this.headerSpacing = false;
      this.setSEO_Tags('Pakistan’s Real Estate Property Portal | Buy & Sell Property', "Find the best property in Pakistan with a complete range of properties for sale, houses for rent in commercial areas and developed house schemes.", false);
    }
    else if(this.activeRoute == '/privacy-policy') {

      this.headerSpacing = false;
      this.setSEO_Tags('Privacy Policy | Gharbaar.com - The Leading Property Portal', "Gharbaar.com - the leading real estate resource for buying and selling property, an easy-to-use portal that takes the hassle out of buying & selling process.", false);
    }
    else if(this.activeRoute == '/terms-conditions') {

      this.headerSpacing = false;
      this.setSEO_Tags('Terms & Conditions | Gharbaar.com: A Leading Property Portal', "Here are the terms & conditions for Gharbaar.com - the leading real estate resource for buying & selling property that takes the hassle out of the process.", false);
    }
    else if(this.activeRoute == '/agent-registration') {

      this.headerSpacing = false;
      this.setSEO_Tags('Agent Registration Form | Contact Us | Gharbaar', "Fill out this agent registration form with your accurate information in all the fields to make sure that you are properly registered.", false);
    }
    else if(this.activeRoute.includes('/blog') || this.activeRoute.includes('/category') ) {
      this.headerSpacing = false;
      this.noHeader      = true;
      this.atBlogPage    = true;

      // Get Blog Categories
      if(this.blogCategories.length == 0) {
        this.getBlogCategories();
      }
    }
    else if(this.activeRoute == '/academy') {

      this.headerSpacing = false;
      this.setSEO_Tags('Real Estate Academy Courses & Training | Gharbaar', "Here are some academy courses to help you understand and gather all the tools, knowledge and information your need related to real estate.", false);
    }
    else if(this.activeRoute == '/manage-my-property') {

      this.headerSpacing = false;
      this.setSEO_Tags('Manage My Property | Gharbaar', "Manage My Property | Gharbaar", true);
    }
    else if(this.activeRoute == '/contact') {

      this.headerSpacing = false;
      this.setSEO_Tags('Contact Us | Gharbaar – Real Estate Property Portal', "Gharbaar is providing a wide range of property in Pakistan including buying and selling of houses and commercial plots. Contact us now.", false);
    }
    else if(this.activeRoute == '/wanted' || this.activeRoute.indexOf('wanted') != -1) {

      this.headerSpacing = false;
      this.sellPages = true;
      this.setSEO_Tags('Wanted Properties in Islamabad and Rawalpindi | Gharbaar', "Wanted Properties in Islamabad and Rawalpindi | Gharbaar", true);
    }
    else if(this.activeRoute == '/wanted/thankyou') {

      this.headerSpacing = false;
      this.setSEO_Tags('Wanted Properties in Islamabad and Rawalpindi | Gharbaar', "Wanted Properties in Islamabad and Rawalpindi | Gharbaar", true);
    }
    else if(this.activeRoute == '/short-term-rentals') {

      this.headerSpacing = false;
      this.setSEO_Tags('Properties for rent for short time period in Islamabad and Rawalpindi | Gharbaar', "Properties for rent for short time period in Islamabad and Rawalpindi | Gharbaar", true);
    }
    else if(this.activeRoute == '/searchlisting-newDesign') {

      this.headerSpacing = false;
      this.setSEO_Tags('Design | Gharbaar', "Design | Gharbaar", true);
    }


    else if(this.activeRoute == '/e-park') {
      this.projectNewPage = true;
      this.headerSpacing = false;
      this.noHeader = true;
      this.setSEO_Tags('E-Park - Luxury Living in Islamabad | Gharbaar', "E-Park is a luxury living project and the first smart building in Pakistan that will integrate advanced technology to bring comfort & ease to your life.", false);
    }

    else if(this.activeRoute == '/e-park/schedule-call') {
      this.projectNewPage = true;
      this.headerSpacing = false;
      this.noHeader = true;
      this.setSEO_Tags('Schedule a Visit at E-Park | Gharbaar', "Schedule a consultation session at E-Park for an in-depth discussion, expert analysis, deals & discounts to make the right decision for your investment.", false);
    }

    else if(this.activeRoute == '/e-park/payment-plan') {
      this.projectNewPage = true;
      this.headerSpacing = false;
      this.noHeader = true;
      this.setSEO_Tags('Payment Plan Calculator - E-Park | Gharbaar', "Free payment plan calculator for E-Park apartments. Calculate the cost per Sq.ft. according to the floor number & type of apartment, size, and view.", false);
    }

    else if(this.activeRoute == '/e-park/payment-plan-calculator') {
      this.projectNewPage = true;
      this.headerSpacing = false;
      this.noHeader = true;
      this.setSEO_Tags('Payment Plan & Floor Plan for E-Park Apartments', "Check out the floor plans for E-Park and calculate the payment plan for your apartment according to the floor number & type of apartment, size, and view.", false);
    }

    else if(this.activeRoute == '/e-park/schedule-call/thankyou') {
      this.projectNewPage = true;
      this.headerSpacing = false;
      this.noHeader = true;
      this.setSEO_Tags('Thank you for Scheduling a Visit at E-Park | Gharbaar', "Thank you for scheduling a consultation session at E-Park. We look forward to helping you make the right decision for your investment.", false);
    }

    else if(this.activeRoute == '/e-park/apartments-for-sale') {
      this.projectNewPage = true;
      this.headerSpacing = false;
      this.noHeader = true;
      this.setSEO_Tags('E-Park Luxury Apartments | RDA Approved | Gharbaar.com', "E-Park is a purely residential project stretching across eight Kanals. The luxury apartments are designed to be earthquake-resistant with SMART technology.", false);
    }
    else if(this.activeRoute == '/e-park/prices') {
      this.noHeader = true;
      this.setSEO_Tags('E-Park - Luxury Living in Islamabad | Gharbaar', "E-Park is a luxury living project and the first smart building in Pakistan that will integrate advanced technology to bring comfort & ease to your life.", false);
    }
    else if(this.activeRoute == '/6th-road-center/schedule-call/thankyou' || this.activeRoute == '/6th-road-center/property-for-sale/thankyou') {
      this.projectNewPage = true;
      this.headerSpacing = false;
      this.noHeader = true;
      this.setSEO_Tags('Thank you for Scheduling a Visit at 6th Road Center | Gharbaar', "Thank you for scheduling a consultation session at 6th Road Center. We look forward to helping you make the right decision for your investment.", false);
    }
    else if(this.activeRoute == '/6th-road-center') {
      this.projectNewPage = true;
      this.headerSpacing = false;
      this.noHeader = true;
      this.setSEO_Tags('6th Road Center - A mix of shops, offices & Clinic space | Gharbaar', "6th Road Center is a commercial building located in Rawalpindi’s business district of 6th Road. A mix of shops, offices & Clinic space. ", false);
    }
    else if(this.activeRoute == '/6th-road-center/schedule-call') {
      this.projectNewPage = true;
      this.headerSpacing = false;
      this.noHeader = true;
      this.setSEO_Tags('Schedule a Visit at 6th Road Center | Gharbaar', "Schedule a consultation session at 6th Road Center for an in-depth discussion, expert analysis, deals & discounts to make the right decision for your investment.", false);
    }
    else if(this.activeRoute.includes('/6th-road-center/property-for-sale')) {


      this.noHeader = true;
      this.setSEO_Tags('6th Road Center | RDA Approved | Gharbaar.com', " 6th Road Center, a RDA-approved project is located in the economic hub of the twin cities which holds retail shops, clinics, & offices all within its walls.", false);
    }
    else if(this.activeRoute == '/6th-road-center/retailshops-prices' || this.activeRoute == '/6th-road-center/clinicalspaces-prices' || this.activeRoute == '/6th-road-center/officespaces-prices') {


      this.noHeader = true;
      this.setSEO_Tags('6th Road Center - A mix of shops, offices & Clinic space | Gharbaar', "6th Road Center is a commercial building located in Rawalpindi’s business district of 6th Road. A mix of shops, offices & Clinic space. ", false);

    }

    else if(this.activeRoute == '/invest') {
      this.investPages = true;
      this.headerSpacing = false;
      this.setSEO_Tags('Invest with confidence! - Gharbaar.com', "Gharbaar.com, the leading real estate resource for property buyers, sellers, landlords, tenants and real estate agents in Pakistan. Real-time residential, commercial, and investment listing updates. Gharbaar.com is the easy-to-use online portal that takes the hassle and uncertainty out of buying, selling, renting, or owning.", false);
    }
    else if(this.activeRoute == '/group-of-companies'){
      // this.headerSpacing = true;

    }

    else if(this.activeRoute == '/skyparkone/reserve') {
      this.noHeader = true;
      this.setSEO_Tags('Reserve Your Unit Today | Limited Slots Available', "Find out more about the unit of your choice including the floor number, type of unit, and price. Limited slots are left. Fill out the form to reserve your unit.", false);
    }

    else if(this.activeRoute == '/skyparkone/reserve-2') {
      this.noHeader = true;
      this.setSEO_Tags('Reserve Your Unit Today | Limited Slots Available', "Find out more about the unit of your choice including the floor number, type of unit, and price. Limited slots are left. Fill out the form to reserve your unit.", false);
    }

    else if(this.activeRoute == '/skyparkone/reserve/thank-you' || this.activeRoute == '/skyparkone/apartments-for-sale/thankyou' || this.activeRoute == '/skyparkone/property-for-sale/thankyou') {
      this.noHeader = true;
      this.setSEO_Tags('Thank you for Registering With Skypark One', "Thank you for showing interest in Skypark One. In order to discuss further details, one of our agents will get in touch with you soon.", false);
    }
    else if(this.activeRoute == '/sell-to-gharbaar' || this.activeRoute.indexOf('sell-to-gharbaar') != -1) {
      this.sellPages = true;
      this.noHeader = true;
      this.setSEO_Tags('Sell Your Property at Profitable Rates with Gharbaar.com', "To sell your property, fill in the form below with your personal details and contact information. One of our agents will contact you soon with an offer.", false);
    }
    else if((this.activeRoute.indexOf('/sell') != -1 || this.activeRoute == '/sell-temp') && this.activeRoute != '/sell-with-gharbaar') {
      this.noHeader = true;
    }
    else if(this.activeRoute == '/sell/thank-you') {
      this.noHeader = true;
      this.setSEO_Tags('Thank you for Submitting Your Offer Request', "Thank you for submitting your offer at Gharbaar.com. We will review the details of your property and various other factors within the provided deadline.", false);
    }
    else if(this.activeRoute == '/skyparkone/skyparkone-landing') {
      this.noHeader = true;
      this.setSEO_Tags('Luxury Apartments for Sale in Islamabad | Skypark One', "Skypark One, a commercial and residential project located in Islamabad is offering luxurious 1, 2, 3-bed apartments. Limited slots are available. Book now!", false);
    }
    else if(this.activeRoute == '/skyparkone/apartments-for-sale') {
      this.noHeader = true;
      this.setSEO_Tags('Luxury Apartments for Sale in Islamabad | Skypark One', "Skypark One, a commercial and residential project located in Islamabad is offering luxurious 1, 2, 3-bed apartments. Limited slots are available. Book now!", false);
    }
    else if(this.activeRoute == '/skyparkone/property-for-sale') {
      this.noHeader = true;
      this.setSEO_Tags('Commercial Shops for Sale in Islamabad | Skypark One', "Invest in the hub of brands - Skypark One, a commercial and residential project located in Islamabad. Only a few slots are available. Book now!", false);
    }

    // ims landing page meta detail
    else if(this.activeRoute == '/ims') {
      this.headerSpacing = false;
      this.setSEO_Tags('Real Estate Inventory Management System | Gharbaar.com', "A fully automated and easy-to-use real estate inventory management system that allows you to maintain a digital record of all your inventory & customer data.", false);
    }
    else if(this.activeRoute == "/invest/bookcall") {
      this.noHeader = true;

    }
    else if(this.activeRoute.indexOf('sell-to-gharbaar') != -1 || this.activeRoute.indexOf('sell/thank-you') != -1 || this.activeRoute.indexOf('6th-road-center') != -1 || this.activeRoute.indexOf('skyparkone') != -1 || this.activeRoute.indexOf('e-park') != -1){
      // if(this.activeRoute == '/dashboard/sell-listings' || this.activeRoute == '/dashboard/wanted-listings') {
      //   this.noHeader = false;
      // } else {
      //   this.noHeader = true;
      // }
      this.noHeader = true;

    }

    else if(this.activeRoute == '**') {
      this.headerSpacing = false;
      this.setSEO_Tags('Page Not Found | Gharbaar', "Contact Us | Page Not Found", true);
    }
    // temporary for homepage new design starts
    else if(this.activeRoute == '/home-temp') {
      this.atHomePage = true;
      this.newDesign = true;
      this.headerSpacing = false;
    }
    // temporary for homepage new design ends

    else {
      this.seoService.setNoIndex(false);
      // this.setSEO_Tags('Page Not Found | Gharbaar', "Contact Us | Page Not Found", true);
    }


    // console.log("this.activeRoute", this.activeRoute);


    // console.log("this.atHomePage", this.atHomePage);
    // console.log("this.atAddPage", this.atAddPage);
    // console.log("this.projectNewPage", this.projectNewPage);



    // else{
    //   this.noHeader = false;
    // }

    if(this.activeRoute.indexOf("/sale") != -1 || this.activeRoute.indexOf("/rent") != -1){
      this.atlisting = false;
    }
    else{
      this.atlisting = true;
    }

    if(this.activeRoute.indexOf("/projects") != -1){
      this.investInner = true;
      this.setSEO_Tags('Commercial Property in Islamabad| All Projects| Gharbaar.com', 'Discover the commercial property projects by Gharbaar.com including E-park, Skypark One, 6th Road Center & many more.', false);
    }
    else{
      this.investInner = false;
    }

    if(window.innerWidth > 991){
      if(!this.activeRoute.includes('/sale') && !this.activeRoute.includes('/rent')){
        this.noFixed= false;
      }
      else{
        this.noFixed= true;
      }
    }
    else{
      this.noFixed= false;
    }

    if (typeof window != "undefined") {
      window.scrollTo(0, 0)
    }

    // console.log('this.noHeader -----> ', this.noHeader);
  }

  ngOnInit() {

    // Subscribe To User Local Login
    if(typeof this.authenticationService.get_currentUserValue() != "undefined") {
      this.authSub = this.authenticationService.currentUserS$.subscribe(user => {
        if (user) {
            this.user           = user.user;
            this.isUserLoggedIn = true;

            // Set User Image
            if (this.user.image && this.user.image.indexOf("https://") == -1 && this.user.image.indexOf("http://") == -1) {
              this.user.image = AppSettings.IMG_ENDPOINT + 'uploads/users/thumbnails/' + this.user.image;
            }
        } else {
            // Move user to homepage
            let self = this;
            setTimeout(function() {
              self.isUserLoggedIn = false;
              if (self.activatedRoute.snapshot['_routerState'].url.indexOf('dashboard') !== -1) {
                // console.log("LoggedOff dashboard")
                self.router.navigate(['/']);
              }
            }, 500);
        }
      });

      // //  Subscribe to User Socail Login
      // if (typeof this.authService != 'undefined') {
      //   this.socail_AuthSub = this.authService.authState.subscribe((user) => {
      //     if (user !== null) {
      //       // Set User
      //       this.user = {
      //         'first_name'            : user.firstName,
      //         'last_name'             : user.lastName,
      //         'email'                 : user.email,
      //       };
      //     }
      //   });
      // }

    }



    // Subscribe to Purpose Change
    this.getPurpose();

    // Subscribe to Property Favorites
    this.getFavoriteProps_Count();

    // Subscribe to Property Favorites
    this.getHiddenProps_Count();

    this.subscriptionForm = this.formBuilder.group({
      email    : [null, [Validators.required, Validators.email]],
    });
  }

  ngAfterViewInit() {
      let self = this;
    if(typeof $ != "undefined"){

        // header search
        $(".search input").on('focus', function(){
            $(this).addClass('focused');
        });
        $(".search input").on('blur', function() {
            $(this).addClass('focused');
            if(!$(this).val()) {
                $(this).removeClass('focused');
            }else{
                $(this).addClass('focused');
            }
        });

        $(".sideBar .has-subMenu").on('click', function(){
            $(this).children(".sidebar-subMenu").slideToggle("fast");
            $(this).toggleClass('clicked');
            $(this).children(".sidebar-subMenu").toggleClass('showSubmenu');
        });

        // close User loggedIn drop down
        if(typeof $ != "undefined") {
          // user dropdown
            $(document).on('click', function(event) {
                if (!$(event.target).closest('.user-dropdown').length && !$(event.target).closest('#userDropdownLogIn').length)  {
                    $(".user-dropdown").removeClass('show');
                    $("#userDropdownLogIn").removeClass('active');
                }
              });
        }
    }
  }
  ngAfterContentInit(): void {
    // show launch product pop-up
    let self = this;
    setTimeout(() => {
      self.togglePopUp('open', 'launchProdModal');
    }, 1500);
  }

  get f() { return this.subscriptionForm.controls; }

  AddSubscribe(){
    this.submitted        = true;

    // Stop here if form is invalid
    if (this.subscriptionForm.invalid) {
     return;
    }

    this.newsLetterLoader = true;
    let url  = AppSettings.API_ENDPOINT + 'subscribers/add';
    this.subscription.is_launch_lead = true;
    this.helperService.httpPostRequests(url, this.subscription)
      .then(resp => {
        //console.log("resp: ",resp);
        this.newsLetterLoader = false;
        if (typeof resp != "undefined" && resp != null && typeof resp.error === "undefined") {
          this.subscription = { email: ''};

          this.newsLetterSuccess = true;
          this.cdr.detectChanges();
          let self = this;
          setTimeout(() => {
            self.newsLetterSuccess = false;
            self.cdr.detectChanges();
          }, 5000);

          // Set Submission false
          this.submitted = false;
        } else {
            console.error(resp);
        }
      })
      .catch(error => {
        console.log("error: ",error);
        this.newsLetterLoader = false;
        Swal.fire('Newsletter Subscription', "You have already subscribed to the newsletter" , 'warning');
        // Set Submission false
        this.submitted = false;
      });
  }
  togglePopUp(toggleParam, modalName = ''){
    if(typeof $ != "undefined"){
      if(toggleParam == 'open'){
        $(".cModal").removeClass("showModal");
        if(modalName){
          $('#'+ modalName).addClass("showModal");
        }
      }
      else if(toggleParam == 'close'){
        $(".cModal").removeClass("showModal");
      }
    }

  }

  ngOnDestroy() {
    if (typeof this.authSub != 'undefined') {
      //prevent memory leak when component destroyed
      this.authSub.unsubscribe();
    }

    if (typeof this.socail_AuthSub != 'undefined') {
      //prevent memory leak when component destroyed
      this.socail_AuthSub.unsubscribe();
    }

    if (typeof this.propuseSub != 'undefined') {
      //prevent memory leak when component destroyed
      this.propuseSub.unsubscribe();
    }

    if (typeof this.favoriteSub != 'undefined') {
      //prevent memory leak when component destroyed
      this.favoriteSub.unsubscribe();
    }

    if (typeof this.hiddenSub != 'undefined') {
      //prevent memory leak when component destroyed
      this.hiddenSub.unsubscribe();
    }

    if (typeof this.routerSubscription != 'undefined') {
      //prevent memory leak when component destroyed
      this.routerSubscription.unsubscribe();

    }
    if (typeof this.selectedTabSub != 'undefined') {
      //prevent memory leak when component destroyed
      this.selectedTabSub.unsubscribe();
    }

  }

  setPurposeRoute(){
    // let routeLink =  this.helperService.investRoute(AppSettings.cities[0]);
    if(this.Purpose != '_I_O')
    return this.helperService.getLocURL({}, AppSettings.cities[1], true, this.Purpose, Object.keys(AppSettings.searchTypes)[0], "");
    else
    return this.helperService.getLocURL({}, AppSettings.cities[1], true, 'sale', Object.keys(AppSettings.searchTypes)[0], "");
  }

  investRoute() {
    let routeLink =  this.helperService.investRoute({"id": 0, "name" : "All"});
    return routeLink;
  }

  searchListingByID() {
    if(!this.searchByID){
      return;
    }
    // Show Loader
    this.searchingLoad = true;
    console.log("this.searchByID", this.searchByID);

    const url = AppSettings.API_ENDPOINT + "property/" + this.searchByID;
    this.helperService.httpGetRequests(url).then(property => {
      // Hide loader
      this.searchingLoad = false;

      // Reset Search
      this.searchByID = "";

      // Open Property Detail Page
      let url = this.helperService.propertyRoute(property);
      this.router.navigate([url]);

    }).catch(error => {
      console.error("error: ",error);

      // Hide loader
      this.searchingLoad = false;

      if(error.status == 404)
      Swal.fire('Error', '404 Property not found', 'error');
    })
  }

  setSEO_Tags(title: string, desc: string, setNoIndex = true) {
    let self = this;
    setTimeout(function() {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();

      // if(setNoIndex)
      self.seoService.setNoIndex(setNoIndex);
    }, 500);
  }

  getPurpose() {
    this.propuseSub = this.purposeService.purposeS$.subscribe((purpose) => {
      this.Purpose = purpose;
    });
  }

  setPurpose(purpose) {
    // this.homePage.setPurpose(purpose);
  }

  getFavoriteProps_Count() {
    this.favoriteSub = this.favoriteService.favoritesS$.subscribe((properties) => {
      this.favoritePropCount = this.favoriteService.getfavorites_Count();
    });
  }

  getHiddenProps_Count() {
    this.hiddenSub = this.hideService.hiddenS$.subscribe((hidden) => {
      this.hiddenPropCount = this.hideService.getHiddenProps_Count();
    });
  }

  lazyLoadEntryComponents() {
    import('../../entry-components/entry-components.module')
    .then(mod => mod.EntryComponentsModule)
    .then(lazyModule => {
      // console.log("lazyModule: ", lazyModule);

      this.LoginComponent = lazyModule.components['LoginComponent'];
      // console.log("this.LoginComponent: ", this.LoginComponent);
      this.lazyEntryCompsLoaded = true;
      this.open_Login_Modal();
    });

  }

  open_SignUpOpts_Modal() {
    const initialState = {};
    this.modalRef = this.modalService.show(this.SiginUpOptsComponent, {initialState});
  }

  open_Login_Modal() {
    const initialState = {};
    // this.modalRef = this.modalService.show(LoginComponent, {initialState});
    this.modalRef = this.modalService.show(this.LoginComponent, {initialState});
    // this.modalRef.content.closeBtnName = 'Close';
  }


  signOut(): void {
    // this.authService.signOut();
  }

  logout() {
    // // Log user out from Social Login
    // if (this.authService['_user'] != null)
    //   this.signOut();

    this.googleLoginService.logout();
    this.facebookLoginService.logout();

    // Show Loader
    this.loading = true;

    this.authenticationService.logout()
    .then(resp => {
      // console.log("resp: ",resp);
      if(resp) {
        // Set User to empty
        this.user           = {
          'first_name'            : '',
          'last_name'             : '',
          'email'                 : '',
          'password'              : '',
          'phone_number'          : '',
          'dob'                   : null,
        };
        // Reset User
        this.cdr.detectChanges();
        this.isUserLoggedIn    = false;
        this.user              = null;
        $(".squareInput").val("");
        // this.router.navigate(['/'])
        // .then(() => {
        //   window.location.reload();
        // });
        if(this.router.url == "/wanted"){
          this.router.navigate(['/wanted'])
          .then(() => {
            window.location.reload();
          });
        }

        // console.log("this.user: ",this.user);
      }

      // Hide Loader
      this.loading = false;
    },
    error => {
      // alert(JSON.stringify(error));
      console.log("error: ",error);

      // Hide Loader
      this.loading = false;
    });
  }

  toggleSidebar() {
    this.toggleSide = !this.toggleSide;
    this.sideNav = !this.sideNav;
    $('body').toggleClass("overflow-hidden");
  }
  closeSideNav(){
    this.toggleSide = false;
    this.sideNav = false;
    $('body').removeClass("overflow-hidden");
  }

  // mblModals(){
  //   let self = this;
  //   if (typeof $ != 'undefined') {
  //     console.log("this.activeRoute2",this.activeRoute2);
  //     if (window.innerWidth <= 575 && !this.isUserLoggedIn && (self.activeRoute2 == "/" || self.activeRoute2 == "/invest")) {
  //         // this.lazyLoadEntryComponents();
  //         $("#mobileFirstScreen").modal("show");
  //     }
  //
  //   }
  // }
  closeModalFirst(param){
    if (typeof $ != 'undefined') {
      if( param == 'home'){
        $("#mobileFirstScreen").modal("hide");
        $('modal-container').hide();
        $('bs-modal-backdrop').hide();

      }
      else{
        if (window.innerWidth <= 575) {
          $("#mobileFirstScreen").modal("hide");
          import('../../entry-components/entry-components.module')
          .then(mod => mod.EntryComponentsModule)
          .then(lazyModule => {
            console.log("lazyModule: ", lazyModule);
            this.SiginUpOptsComponent = lazyModule.components['SiginUpOptsComponent'];
            // this.modalRef = this.modalService.show(SiginUpOptsComponent, {initialState});
            this.lazyEntryCompsLoaded = true;
            this.open_SignUpOpts_Modal();
          });

        }
      }
    }
  }

  // sideBar
  showSidebar() {
      this.initSidebar = !this.initSidebar;
      if(this.initSidebar) {
          $(".sideBar").addClass('showSidebar');
          $("body").addClass('bodyFixed');
      } else {
          $(".sideBar").removeClass('showSidebar');
          $("body").removeClass('bodyFixed');
      }
  }

  showBlogSidebar() {
      this.initblogSidebar = !this.initblogSidebar;
      if(this.initblogSidebar) {
          $(".sideBarBlog").addClass('showSidebar');
          $("body").addClass('bodyFixed');
      } else {
          $(".sideBarBlog").removeClass('showSidebar');
          $("body").removeClass('bodyFixed');
      }
  }

  getBlogCategories() {
    const url = AppSettings.API_ENDPOINT + 'blogCategories/get';
    let data = {};
    this.helperService.httpPostRequests(url, data).then(resp => {
      if (typeof resp != "undefined") {
        this.blogCategories = resp;

        // Set Routes
        for (let i = 0; i < this.blogCategories.length; i++) {
          this.blogCategories[i].route = this.helperService.get_category_URL(this.blogCategories[i]);
        }
      }
    }).catch(error => {
        console.error("error: ",error);
    });
  }

  scrollTo(section) {
    if(typeof $ != "undefined"){
      var x = $("#" + section).offset();
      console.log("x.top is: ", x.top);
      window.scrollTo({ top: x.top - 120, behavior: 'smooth'});
      $("body").removeClass("body-overflow");

    }
  }

  // toggle login dropDown
  toggleLoginDropDown(){
    // Toggle User loggedIn drop down
    this.loginDropDown = !this.loginDropDown;
  }

}
