import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule, PreloadAllModules, NoPreloading } from '@angular/router';
import { AuthGuard } from './services/_helpers';

// Import Pages
// import { HomeComponent } from './Site/pages/home/home.component';

const routes: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./layouts/user-dashboard-layout/user-dashboard-layout.module').then(m => m.UserDashboardLayoutModule)
  },

  // Routes for Elite-Investors
  { path: 'invest',                           loadChildren: () => import('./Site/pages/elite-investors/elite-investors.module').then(m => m.EliteInvestorsModule) },
  // { path: '',                                 redirectTo: 'invest', pathMatch: 'full' },
  { path: 'invest/bookcall',                  loadChildren: () => import('./Site/pages/investor-bookcall/investor-bookcall.module').then(m => m.InvestorBookcallModule) },
  { path: 'invest/bookcall/thankyou',         loadChildren: () => import('./Site/pages/investor-thankyou/investor-thankyou.module').then(m => m.InvestorThankyouModule) },

  { path: 'old-home-2',                                 loadChildren: () => import('./Site/pages/home-new/home-new.module').then(m => m.HomeNewModule) },

  { path: 'old-home',                         loadChildren: () => import('./Site/pages/home/home.module').then(m => m.HomeModule) },
  { path: '',                                 loadChildren: () => import('./Site/pages/home-temp/home-temp.module').then(m => m.HomeTempModule) },

  // commented on 14-dec-2021 because new page
  // { path: 'index.php',                        loadChildren: () => import('./Site/pages/home/home.module').then(m => m.HomeModule) },
  // { path: 'index.php',                        loadChildren: () => import('./Site/pages/home-new/home-new.module').then(m => m.HomeNewModule) },
  { path: 'index.php',                        loadChildren: () => import('./Site/pages/home-temp/home-temp.module').then(m => m.HomeTempModule) },
  // { path: '',                                 component: HomeComponent },
  { path: 'gharbaar-presentation',            loadChildren: () => import('./Site/pages/gb-presentation/gb-presentation.module').then(m => m.GbPresentationModule) },

  // sell property new page
  // { path: 'sell',                    loadChildren: () => import('./Site/pages/sell-property/sell-property.module').then(m => m.SellPropertyModule) },
  { path: 'sell/thank-you',          loadChildren: () => import('./Site/pages/sell-property/sell-property-thankyou/sell-property-thankyou.module').then(m => m.SellPropertyThankyouModule) },
  // { path: 'sell-to-gharbaar',        loadChildren: () => import('./Site/pages/sell-property/new-sell/new-sell.module').then(m => m.NewSellModule) },
  // { path: 'wanted',                  loadChildren: () => import('./Site/pages/wanted/wanted.module').then(m => m.WantedModule) },
  // { path: 'wanted/thankyou',         loadChildren: () => import('./Site/pages/wanted/wanted-thankyou/wanted-thankyou.module').then(m => m.WantedThankyouModule) },

  // temp we buy module for new design
  { path: 'sell-with-gharbaar',               loadChildren: () => import('./Site/pages/we-buy-landing/we-buy-landing.module').then(m => m.WeBuyLandingModule) },
  { path: 'sell',                             loadChildren: () => import('./Site/pages/we-buy/we-buy.module').then(m => m.WeBuyModule) },
  { path: 'sell/:propId',                     loadChildren: () => import('./Site/pages/we-buy/we-buy.module').then(m => m.WeBuyModule) },

  // temp we buy module for new design
  { path: 'sell-temp',               loadChildren: () => import('./Site/pages/we-buy-new/we-buy-new.module').then(m => m.WeBuyNewModule) },

  // Listing related routes
  // add becomes sell
  // { path: 'add',                              loadChildren: () => import('./Site/pages/add-property/add-property.module').then(m => m.AddPropertyModule) },
  // { path: 'add',                                 redirectTo: 'sell', pathMatch: 'full' },
  { path: 'add',                              loadChildren: () => import('./Site/pages/add-property/add-property.module').then(m => m.AddPropertyModule) },

  // { path: 'sale/:type/:slug',                 loadChildren: () => import('./Site/pages/search-listing-new-design/search-listing-new-design.module').then(m => m.SearchListingNewDesignModule) },
  // { path: 'sale/:type/:slug/:filter',         loadChildren: () => import('./Site/pages/search-listing-new-design/search-listing-new-design.module').then(m => m.SearchListingNewDesignModule) },
  { path: 'rent/:type/:slug',                 loadChildren: () => import('./Site/pages/search-listing-new-design/search-listing-new-design.module').then(m => m.SearchListingNewDesignModule) },
  { path: 'rent/:type/:filter',               loadChildren: () => import('./Site/pages/search-listing-new-design/search-listing-new-design.module').then(m => m.SearchListingNewDesignModule) },

  { path: 'oldsale/:type/:slug',              loadChildren: () => import('./Site/pages/search-listing-old/search-listing-old.module').then(m => m.SearchListingOldModule) },

  { path: 'sale/:type/:slug',                  loadChildren: () => import('./Site/pages/search-listing/search-listing.module').then(m => m.SearchListingModule) },
  { path: 'sale/:type/:slug/:filter',         loadChildren: () => import('./Site/pages/search-listing/search-listing.module').then(m => m.SearchListingModule) },

  // { path: 'property/:slug',                   loadChildren: () => import('./Site/pages/listing-detail/listing-detail.module').then(m => m.ListingDetailModule) },
  // { path: 'listing-detail-new',               loadChildren: () => import('./Site/pages/listing-detail-new/listing-detail-new.module').then(m => m.ListingDetailNewModule) },
  { path: 'property/:slug',               loadChildren: () => import('./Site/pages/listing-detail-new/listing-detail-new.module').then(m => m.ListingDetailNewModule) },

  // Invest Routes
  // { path: 'e-park2',                             loadChildren: () => import('./Site/pages/ecstasypark/ecstasypark.module').then(m => m.EcstasyparkModule) },
  // { path: 'e-park/schedule-call2',               loadChildren: () => import('./Site/pages/schedule-visit-ecs/schedule-visit-ecs.module').then(m => m.ScheduleVisitEcsModule) },
  // { path: 'e-park/schedule-call/thankyou2',      loadChildren: () => import('./Site/pages/ecstasy-thankyou/ecstasy-thankyou.module').then(m => m.EcstasyThankyouModule) },
  { path: 'e-park/registration-form/thankyou',  loadChildren: () => import('./Site/pages/ecs-booking-thankyou/ecs-booking-thankyou.module').then(m => m.EcsBookingThankyouModule) },
  // { path: 'ecstasypark/payment-plan',            loadChildren: () => import('./Site/pages/ecstasypark-calculator/ecstasypark-calculator.module').then(m => m.EcstasyparkCalculatorModule) },
  // { path: 'e-park/payment-plan-calculator',     loadChildren: () => import('./Site/pages/ecstasypark-calculator/ecstasypark-calculator.module').then(m => m.EcstasyparkCalculatorModule) },
  { path: 'e-park/registration-form',           loadChildren: () => import('./Site/pages/ecstasy-booking-form/ecstasy-booking-form.module').then(m => m.EcstasyBookingFormModule) },

  { path: 'ecstasypark',                             redirectTo: 'e-park' },
  { path: 'ecstasypark/schedule-call',               redirectTo: 'e-park/schedule-call' },
  { path: 'ecstasypark/schedule-call/thankyou',      redirectTo: 'e-park/schedule-call/thankyou' },
  { path: 'ecstasypark/registration-form/thankyou',  redirectTo: 'e-park/registration-form/thankyou' },
  { path: 'ecstasypark/payment-plan-calculator',     redirectTo: 'e-park/payment-plan-calculator' },
  { path: 'ecstasypark/registration-form',           redirectTo: 'e-park/registration-form' },

  { path: 'e-park',                                   loadChildren: () => import('./Site/pages/projects-folder/e-park/e-park-proj/e-park-proj.module').then(m => m.EParkProjModule) },
  { path: 'e-park/prices',                            loadChildren: () => import('./Site/pages/projects-folder/e-park/prices/prices.module').then(m => m.PricesModule) },
  { path: 'e-park/unit-detail/:slug',                 loadChildren: () => import('./Site/pages/projects-folder/e-park/unit-detail/unit-detail.module').then(m => m.UnitDetailModule) },
  { path: 'e-park/schedule-call',                     loadChildren: () => import('./Site/pages/projects-folder/e-park/schedule-visit/schedule-visit.module').then(m => m.ScheduleVisitModule) },
  { path: 'e-park/schedule-call/thankyou',            loadChildren: () => import('./Site/pages/projects-folder/e-park/e-park-thankyou/e-park-thankyou.module').then(m => m.EParkThankyouModule) },
  { path: 'e-park/apartments-for-sale',               loadChildren: () => import('./Site/pages/projects-folder/e-park/landing-page/landing-page.module').then(m => m.LandingPageModule) },



  { path: '6th-road-center',                         loadChildren: () => import('./Site/pages/projects-folder/sixth-road/sixth-road.module').then(m => m.SixthRoadModule) },
  // { path: '6th-road-center',                         loadChildren: () => import('./Site/pages/projects-folder/sixth-road/sixth-road-project/sixth-road-project.module').then(m => m.SixthRoadProjectModule) },
  // { path: '6th-road-center/retailshops-prices',      loadChildren: () => import('./Site/pages/projects-folder/sixth-road/prices/prices.module').then(m => m.PricesModule) },
  // // { path: '6th-road-center/foodcourt-prices',              loadChildren: () => import('./Site/pages/projects-folder/sixth-road/prices/prices.module').then(m => m.PricesModule) },
  // { path: '6th-road-center/clinicalspaces-prices',   loadChildren: () => import('./Site/pages/projects-folder/sixth-road/prices/prices.module').then(m => m.PricesModule) },
  // { path: '6th-road-center/officespaces-prices',     loadChildren: () => import('./Site/pages/projects-folder/sixth-road/prices/prices.module').then(m => m.PricesModule) },
  // { path: '6th-road-center/payment-plan',            loadChildren: () => import('./Site/pages/projects-folder/sixth-road/road6-calculator/road6-calculator.module').then(m => m.Road6CalculatorModule) },
  // { path: '6th-road-center/schedule-call',           loadChildren: () => import('./Site/pages/projects-folder/sixth-road/schedule-visit-6th/schedule-visit-6th.module').then(m => m.ScheduleVisit6thModule) },
  // { path: '6th-road-center/exclusive-offer',         loadChildren: () => import('./Site/pages/projects-folder/sixth-road/schedule-visit-6th/schedule-visit-6th.module').then(m => m.ScheduleVisit6thModule) },
  // { path: '6th-road-center/schedule-call/thankyou',  loadChildren: () => import('./Site/pages/projects-folder/sixth-road/sixth-thankyou/sixth-thankyou.module').then(m => m.SixthThankyouModule) },
  // { path: '6th-road-center/unit-detail/:slug',       loadChildren: () => import('./Site/pages/projects-folder/sixth-road/unit-detail/unit-detail.module').then(m => m.UnitDetailModule) },
  // { path: '6th-road-center/property-for-sale',       loadChildren: () => import('./Site/pages/projects-folder/sixth-road/landing-page/landing-page.module').then(m => m.LandingPageModule) },
  // { path: '6th-road-center/property-for-sale/thankyou',  loadChildren: () => import('./Site/pages/projects-folder/sixth-road/sixth-thankyou/sixth-thankyou.module').then(m => m.SixthThankyouModule) },

  { path: 'projects/:slug',                      loadChildren: () => import('./Site/pages/projects/projects.module').then(m => m.ProjectsModule) },

  { path: 'blue-world-city',                  loadChildren: () => import('./Site/pages/blue-world-city/blue-world-city.module').then(m => m.BlueWorldCityModule) },
  { path: 'blue-world-city-mob',              loadChildren: () => import('./Site/pages/blue-world-city/blue-world-city.module').then(m => m.BlueWorldCityModule) },
  { path: 'blue-world-city/hot-offer',        loadChildren: () => import('./Site/pages/hot-offer/hot-offer.module').then(m => m.HotOfferModule) },
  { path: 'blue-world-city/overseas-block',   loadChildren: () => import('./Site/pages/overseas-block/overseas-block.module').then(m => m.OverseasBlockModule) },
  { path: 'blue_world_city/overseas_block',   redirectTo: 'blue-world-city/overseas-block' },
  { path: 'blueworldcity/overseas_block',     redirectTo: 'blue-world-city/overseas-block' },
  { path: 'blueworldcity',                    redirectTo: 'blue-world-city' },
  { path: 'blue_world_city',                  redirectTo: 'blue-world-city' },



  { path: 'skyparkone-old',                   loadChildren: () => import('./Site/pages/skyparkone/skyparkone.module').then(m => m.SkyparkoneModule) },

  // { path: 'skyparkone',                       loadChildren: () => import('./Site/pages/skypark-new/skypark-new.module').then(m => m.SkyparkNewModule) },
  { path: 'skyparkone',                       loadChildren: () => import('./Site/pages/projects-folder/skyparkone/skyparkone.module').then(m => m.SkyparkoneModule) },
  { path: 'karakoram-enterprises',                       loadChildren: () => import('./Site/pages/projects-folder/karakoram-enterprises/karakoram-enterprises.module').then(m => m.KarakoramEnterprisesModule) },
  // { path: 'skyparkone',                                                 loadChildren: () => import('./Site/pages/projects-folder/skyparkone/skyparkone-project/skyparkone-project.module').then(m => m.SkyparkoneProjectModule) },
  // // { path: 'skyparkone/landing-asjkbd1j2kasb5djk9asbd8jkasbnjk',         loadChildren: () => import('./Site/pages/projects-folder/skyparkone/skyparkone-landing/skyparkone-landing.module').then(m => m.SkyparkoneLandingModule) },
  // { path: 'skyparkone/skyparkone-landing',                              loadChildren: () => import('./Site/pages/projects-folder/skyparkone/skyparkone-landing/skyparkone-landing.module').then(m => m.SkyparkoneLandingModule) },
  // { path: 'skyparkone/apartments-for-sale',                             loadChildren: () => import('./Site/pages/projects-folder/skyparkone/skyparkone-landing-new/skyparkone-landing-new.module').then(m => m.SkyparkoneLandingNewModule) },
  // { path: 'skyparkone/property-for-sale',                               loadChildren: () => import('./Site/pages/projects-folder/skyparkone/skyparkone-commercial/skyparkone-commercial.module').then(m => m.SkyparkoneCommercialModule) },
  // // { path: 'skyparkone/skyparkone-landing',                              redirectTo: 'skyparkone/landing-asjkbd1j2kasb5djk9asbd8jkasbnjk' },
  // { path: 'skyparkone/residential-prices',                              loadChildren: () => import('./Site/pages/projects-folder/skyparkone/choose-unit/choose-unit.module').then(m => m.ChooseUnitModule) },
  // { path: 'skyparkone/foodcourt-prices',                                loadChildren: () => import('./Site/pages/projects-folder/skyparkone/choose-unit/choose-unit.module').then(m => m.ChooseUnitModule) },
  // { path: 'skyparkone/retailshops-prices',                              loadChildren: () => import('./Site/pages/projects-folder/skyparkone/choose-unit/choose-unit.module').then(m => m.ChooseUnitModule) },
  // { path: 'skyparkone/schedule-visit',                                  loadChildren: () => import('./Site/pages/projects-folder/skyparkone/schedule-visit/schedule-visit.module').then(m => m.ScheduleVisitModule) },
  // { path: 'skyparkone/schedule-visit/thankyou',                         loadChildren: () => import('./Site/pages/projects-folder/skyparkone/thankyou/thankyou.module').then(m => m.ThankyouModule) },
  // { path: 'skyparkone/apartments-for-sale/thankyou',                    loadChildren: () => import('./Site/pages/projects-folder/skyparkone/thankyou/thankyou.module').then(m => m.ThankyouModule) },
  // { path: 'skyparkone/property-for-sale/thankyou',                    loadChildren: () => import('./Site/pages/projects-folder/skyparkone/thankyou/thankyou.module').then(m => m.ThankyouModule) },
  // { path: 'skyparkone/unit-detail/:slug',                               loadChildren: () => import('./Site/pages/projects-folder/skyparkone/unit-detail/unit-detail.module').then(m => m.UnitDetailModule) },
  //
  // { path: 'skyparkone-mob',                   loadChildren: () => import('./Site/pages/skypark-new/skypark-new.module').then(m => m.SkyparkNewModule) },
  // { path: 'skyparkone/payment-plan',          loadChildren: () => import('./Site/pages/payment-plan/payment-plan.module').then(m => m.PaymentPlanModule) },
  // { path: 'skyparkone/reserve',               loadChildren: () => import('./Site/pages/projects-folder/skyparkone/registration-form/registration-form.module').then(m => m.RegistrationFormModule) },
  // { path: 'skyparkone/reserve-2',             loadChildren: () => import('./Site/pages/projects-folder/skyparkone/registration-form/registration-form.module').then(m => m.RegistrationFormModule) },
  // { path: 'skyparkone/reserve/thank-you',     loadChildren: () => import('./Site/pages/projects-folder/skyparkone/registration-form/registration-form.module').then(m => m.RegistrationFormModule) },




  { path: 'capital-smart-city',               loadChildren: () => import('./Site/pages/capitalsmartcity/capitalsmartcity.module').then(m => m.CapitalsmartcityModule) },
  { path: 'capital-smart-city-mob',           loadChildren: () => import('./Site/pages/capitalsmartcity/capitalsmartcity.module').then(m => m.CapitalsmartcityModule) },

  // Find-a-pro Routes
  { path: 'find-pro',                         loadChildren: () => import('./Site/pages/find-pro/find-pro.module').then(m => m.FindProModule) },
  { path: 'find-pro/:category_slug/:slug',    loadChildren: () => import('./Site/pages/find-pro-results/find-pro-results.module').then(m => m.FindProResultsModule) },

  // Routes for Agents / Agency Profile
  { path: 'agent/:slug',                      loadChildren: () => import('./Site/pages/agent-profile/agent-profile.module').then(m => m.AgentProfileModule) },

  // Pros detail Pages
  { path: 'interior-designer/:slug',          loadChildren: () => import('./Site/pages/find-pro-details/find-pro-details.module').then(m => m.FindProDetailsModule) },
  { path: 'architect/:slug',                  loadChildren: () => import('./Site/pages/find-pro-details/find-pro-details.module').then(m => m.FindProDetailsModule) },
  { path: 'contractors-builder/:slug',        loadChildren: () => import('./Site/pages/find-pro-details/find-pro-details.module').then(m => m.FindProDetailsModule) },
  { path: 'property-lawyer/:slug',            loadChildren: () => import('./Site/pages/find-pro-details/find-pro-details.module').then(m => m.FindProDetailsModule) },
  { path: 'home-insurance/:slug',             loadChildren: () => import('./Site/pages/find-pro-details/find-pro-details.module').then(m => m.FindProDetailsModule) },
  { path: 'home-automation/:slug',            loadChildren: () => import('./Site/pages/find-pro-details/find-pro-details.module').then(m => m.FindProDetailsModule) },
  { path: 'home-security/:slug',              loadChildren: () => import('./Site/pages/find-pro-details/find-pro-details.module').then(m => m.FindProDetailsModule) },

  { path: 'locations/:city',                  loadChildren: () => import('./Site/pages/view-all-locations/view-all-locations.module').then(m => m.ViewAllLocationsModule) },

  // Blogging routes
  { path: 'blog',                             loadChildren: () => import('./Site/pages/blogFolder/blogs/blogs.module').then(m => m.BlogsModule) },
  { path: 'blog/:slug',                       loadChildren: () => import('./Site/pages/blogFolder/blogs-detail/blogs-detail.module').then(m => m.BlogsDetailModule)  },
  { path: 'blog-detail',                      loadChildren: () => import('./Site/pages/blogFolder/blogs-detail/blogs-detail.module').then(m => m.BlogsDetailModule)  },
  { path: 'author/:slug',                     loadChildren: () => import('./Site/pages/blogFolder/author/author.module').then(m => m.AuthorModule) },
  { path: 'category/:slug',                   loadChildren: () => import('./Site/pages/blogFolder/blogs/blogs.module').then(m => m.BlogsModule) },
  // { path: 'category/:slug',                   loadChildren: () => import('./Site/pages/blogFolder/blog-category/blog-category.module').then(m => m.BlogCategoryModule) },

  // MISC pages
  { path: 'manage-my-property',               loadChildren: () => import('./Site/pages/manage-my-property/manage-my-property.module').then(m => m.ManageMyPropertyModule) },
  { path: 'academy',                          loadChildren: () => import('./Site/pages/academy/academy.module').then(m => m.AcademyModule) },
  { path: 'about-us',                         loadChildren: () => import('./Site/pages/about-us/about-us.module').then(m => m.AboutUsModule) },
  { path: 'about-team',                       loadChildren: () => import('./Site/pages/about-team/about-team.module').then(m => m.AboutTeamModule) },
  { path: 'about-ceo',                        loadChildren: () => import('./Site/pages/about-ceo/about-ceo.module').then(m => m.AboutCeoModule) },
  { path: 'testimonials',                     loadChildren: () => import('./Site/pages/testimonials/testimonials.module').then(m => m.TestimonialsModule) },
  { path: 'group-of-companies',               loadChildren: () => import('./Site/pages/group-of-companies/group-of-companies.module').then(m => m.GroupOfCompaniesModule) },
  { path: 'jobs',                          loadChildren: () => import('./Site/pages/careers/careers.module').then(m => m.CareersModule) },
  { path: 'job-description',                  loadChildren: () => import('./Site/pages/job-description/job-description.module').then(m => m.JobDescriptionModule) },
  { path: 'contact',                          loadChildren: () => import('./Site/pages/contact/contact.module').then(m => m.ContactModule) },
  { path: 'privacy-policy',                   loadChildren: () => import('./Site/pages/privacy/privacy.module').then(m => m.PrivacyModule) },
  { path: 'terms-conditions',                 loadChildren: () => import('./Site/pages/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule) },

  // product Routes

  { path: 'ims',                              loadChildren: () => import('./Site/pages/ims-landing/ims-landing.module').then(m => m.ImsLandingModule) },
  { path: 'crm',                              loadChildren: () => import('./Site/pages/crm-landing/crm-landing.module').then(m => m.CrmLandingModule) },
  { path: 'marketing',                        loadChildren: () => import('./Site/pages/marketing-landing/marketing-landing.module').then(m => m.MarketingLandingModule) },

  { path: 'kingdom-valley',                  loadChildren: () => import('./Site/pages/kingdom-valley/kingdom-valley.module').then(m => m.KingdomValleyModule) },
  { path: 'park-view-city',                  loadChildren: () => import('./Site/pages/park-view-city/park-view-city.module').then(m => m.ParkViewCityModule) },
  { path: 'rudn-enclave',                    loadChildren: () => import('./Site/pages/rudn-enclave/rudn-enclave.module').then(m => m.RudnEnclaveModule) },
  { path: 'investing-guide',                 loadChildren:() => import ('./Site/pages/free-training/investing-guide/investing-guide.module').then (m => m.InvestingGuideModule)},
  { path: 'investing-guide/:slug',           loadChildren:() => import ('./Site/pages/free-training/investing-guide-detail/investing-guide-detail.module').then (m => m.InvestingGuideDetailModule)},

  { path: 'real-estate-investing',                   loadChildren:() => import ('./Site/pages/free-training/real-estate-investing/real-estate-investing.module').then (m => m.RealEstateInvestingModule)},
  { path: 'real-estate-investing/:module',           loadChildren:() => import ('./Site/pages/free-training/training-module/training-module.module').then (m => m.TrainingModuleModule)},
  { path: 'real-estate-investing/:module/:lesson',   loadChildren:() => import ('./Site/pages/free-training/training-videos/training-videos.module').then (m => m.TrainingVideosModule)},

  // { path: 'money-education',                 loadChildren:() => import ('./Site/pages/free-training/money-education/money-education.module').then (m=> m.MoneyEducationModule)},
  { path: 'money-education',                   loadChildren:() => import ('./Site/pages/free-training/real-estate-investing/real-estate-investing.module').then (m => m.RealEstateInvestingModule)},
  { path: 'money-education/:module',           loadChildren:() => import ('./Site/pages/free-training/training-module/training-module.module').then (m => m.TrainingModuleModule)},
  { path: 'money-education/:module/:lesson',   loadChildren:() => import ('./Site/pages/free-training/training-videos/training-videos.module').then (m => m.TrainingVideosModule)},

  { path: 'training-module',                 loadChildren:() => import ('./Site/pages/free-training/training-module/training-module.module').then (m=> m.TrainingModuleModule)},
  { path: 'training-videos',                 loadChildren:() => import ('./Site/pages/free-training/training-videos/training-videos.module').then (m=> m.TrainingVideosModule)},
  // 404 Routes
  { path: '404',                              loadChildren: () => import('./Site/pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) },
  { path: '**',                               loadChildren: () => import('./Site/pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) },

  {
    path: 'login',
    // redirectTo: 'login',
    redirectTo: '',
    pathMatch: 'full',
  },



];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: false,
      // preloadingStrategy: PreloadAllModules,
      preloadingStrategy: NoPreloading,
      initialNavigation: 'enabled'
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
