import { Component, Input, OnInit, Renderer2, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';

declare var window;

@Component({
  selector: 'app-tawk-to',
  templateUrl: './tawk-to.component.html',
  styleUrls: ['./tawk-to.component.css']
})
export class TawkToComponent implements OnInit {

  @Input() id: string;
  public tawkTo_Script : any;

  constructor(
    private _renderer: Renderer2,
    @Inject(DOCUMENT) private _document,
    private el:ElementRef
  ) { }

  ngOnInit() {

    // if(typeof window['$_Tawk'] != "undefined") {
    //     delete window['$_Tawk'];
    //     delete window['$_Tawk_AccountKey'];
    //     delete window['$_Tawk_Unstable'];
    //     delete window['$_Tawk_WidgetId'];
    //     delete window['$__TawkEngine'];
    //     delete window['$__TawkSocket'];
    // } else {
    //     console.log("******* window: ",window);
    // }

    // this.tawkTo_Script = this._renderer.createElement('script');
    // this._renderer.setProperty(this.tawkTo_Script, 'id', 'tawk_to_add');
    // // console.log("this.tawkTo_Script: ",this.tawkTo_Script);
    // this.tawkTo_Script.text = `var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    //                             (function () {
    //                               var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
    //                               s1.async = true;
    //                               s1.src = 'https://embed.tawk.to/${this.id}/default';
    //                               s1.charset = 'UTF-8';
    //                               s1.setAttribute('crossorigin', '*');
    //                               s0.parentNode.insertBefore(s1, s0);
    //                             })();`;
    //
    // // this._renderer.appendChild(this._document.body, this.tawkTo_Script);
    // // $('body').append(this.tawkTo_Script);
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if(typeof $ != "undefined") {
      // Append Tawk-to
      $('body').append('<script id="tawk_to_add" async="" src="https://embed.tawk.to/'+this.id+'" charset="UTF-8" crossorigin="*"></script>');
    }
  }

  ngOnDestroy() {
    // Remove Tawk-to
    if(typeof window != "undefined") {
      if(typeof window.$_Tawk != "undefined") {
          delete window.$_Tawk;
          delete window.$_Tawk_AccountKey;
          delete window.$_Tawk_Unstable;
          delete window.$_Tawk_WidgetId;
          delete window.$__TawkEngine;
          delete window.$__TawkSocket;
          delete window.TawkClass;
          delete window.Tawk_API;
      }

      if(typeof $ != "undefined") {
        $($('#tawk_to_add').next()[0]).remove();
        $('#tawk_to_add').remove();
      }
    }
  }

}
