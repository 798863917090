import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class SkyparkReserve {
  public reserveListUnit     = null;
  public reserveListFloor    = null;

  constructor() {
  }

  getReserve() {
    return { "reserveListUnit"     : this.reserveListUnit,
              "reserveListFloor"   : this.reserveListFloor};
  }

  setReserve(dataUnit: any = null,dataFloor: any = null) {
    if(dataUnit != null)
      this.reserveListUnit  = dataUnit;
    if(dataFloor != null)
      this.reserveListFloor = dataFloor;
  }
}
