import { Component, ApplicationRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppSettings, HelperService } from './services/_services';
declare var  AOS;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  public isProjectPage      : any;
  public showHeader         : any = true;
  public map_togfooter      : any = false;
  // public showDashboard_Nav  : any = false;
  // public mobilePages        : any = false;

  // Subscriptions
  public routerSubscription : any;

  constructor(
    private helperService   : HelperService,
    private router          : Router
  ){
    let searchTypes_Seq = {};
    searchTypes_Seq['Properties'] = { id: 0, type: 'all', singular: 'Property', plural: 'Properties' };
    let types           = Object.keys(AppSettings.searchTypes);
    let sub_types       = AppSettings.searchTypes;

    let id = 1;
    for (let i = 0; i < types.length; i++) {
      // Get Sub_Types of the parent type
      let subs = sub_types[types[i]];

      // Set Parent Type
      searchTypes_Seq[types[i]] = { id : id, type: 'type', singular: subs[0].pTypeSigular, plural: subs[0].pTypePlural };

      // Set SubType
      for (let j = 0; j < subs.length; j++) {
        id++;
        searchTypes_Seq[subs[j].value] = { id : id, type: 'sub_type', parent: types[i], singular: subs[j].value, plural: subs[j].plural };
      }
      id++;
    }

    // Init AOS
    if(typeof AOS != "undefined") {
      AOS.init({once: true});
    }

    // Set Search Sequences
    this.helperService.setSearchTypes_Seq(searchTypes_Seq);

    this.routerSubscription = this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {

        // Hide Header if needed
        // if(val.url.indexOf('dashboard') !== -1)
        //   this.showHeader = false;
        // else
        if (val.url == "/blue-world-city-mob" || val.url == "/capital-smart-city-mob" || val.url == "/skyparkone-mob")
        this.showHeader = false;
        else if (val.url == "/we-buy")
        {
          $('#y4akhfba3i71647410883410').css('display','none');
        }
        else
        this.showHeader = true;
        // console.log("--- this.showHeader: ",this.showHeader);
        if(val.url.indexOf('blue-world-city') !== -1)
        this.isProjectPage = true;
        else
        this.isProjectPage = false;

        if (typeof window != "undefined") {
          if((val.url.indexOf('sale') === 1 || val.url.indexOf('rent') === 1 || (val.url.indexOf('find-pro') === 1 && val.url !== '/find-pro')) && (window.innerWidth > 768)) {
            this.map_togfooter = true;
          }
          else {
            this.map_togfooter = false;
          }
        }
      }
    });
  }

  ngOnDestroy() {
    if (typeof this.routerSubscription != 'undefined') {
      //prevent memory leak when component destroyed
      this.routerSubscription.unsubscribe();
    }
  }
}
