import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class FavoriteService {
    public favorites     : any = {};
    private favoritesS   : BehaviorSubject<any> = new BehaviorSubject<any>(this.favorites);
    favoritesS$          : Observable<any> = this.favoritesS.asObservable();

    constructor() {
      if (typeof localStorage != 'undefined') {
        this.favorites = JSON.parse(localStorage.getItem('favoriteProps'));
        if(this.favorites == null)
          this.favorites = {};

        this.favoritesS.next(this.favorites);
      }
    }

    getfavorites() {
      return this.favorites;
    }

    getfavorites_array() {
      let temp_arr = [];
      for (let key in this.favorites) {
          temp_arr.push(this.favorites[key]);
      }
      return temp_arr;
    }

    getfavorites_Count() {
      var keys = Object.keys(this.favorites);
      return keys.length;
    }

    addfavoriteProp(favoriteProp: any) {
      if (typeof favoriteProp.id == "undefined") {
        favoriteProp.id = favoriteProp.p_id;
      }

      if (typeof favoriteProp.p_id == "undefined") {
        favoriteProp.p_id = favoriteProp.id;
      }

      this.favorites[favoriteProp.id] = favoriteProp;
      this.favoritesS.next(this.favorites);

      console.log("this.favorites: ",this.favorites);

      localStorage.setItem('favoriteProps', JSON.stringify(this.favorites));
    }

    getFavoritePropByIndex(index: any) {
      if(this.favorites[index])
        return true;
      else
        return false;
    }

    removefavoriteProp(index: any) {
      delete this.favorites[index];
      // this.favorites.splice(index, 1);
      this.favoritesS.next(this.favorites);

      console.log("this.favorites: ",this.favorites);

      localStorage.setItem('favoriteProps', JSON.stringify(this.favorites));
    }
}
