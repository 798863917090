import { Component, Input, OnInit, Renderer2, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';

declare var window;

@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.css']
})
export class MessengerComponent implements OnInit {

  @Input() id          : string;

  constructor(
    private _renderer: Renderer2,
    @Inject(DOCUMENT) private _document,
    private el:ElementRef
  ) { }

  ngOnInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if(typeof $ != "undefined") {
      if(typeof window != 'undefined' && (!window.FB || !window.FB.CustomerChat)) {
          let json = `var chatbox = document.getElementById('fb-customer-chat');
          chatbox.setAttribute("page_id", "`+this.id+`");
          chatbox.setAttribute("attribution", "biz_inbox");

          window.fbAsyncInit = function() {
            FB.init({
              xfbml            : true,
              version          : 'v11.0'
            });
          };

          (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));`;

          let d  = this._document;
          let s  = 'script';
          let id = 'messenger-js';

          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {return;}
          js = d.createElement(s);
          js.id = id;
          js.type = "text/javascript";
          js.innerHTML = json;
          // var b = document.getElementsByName("body");
          // d.body.appendChild(js);
          // (b as any).appendChild(js);

          setTimeout(() => {
            fjs.parentNode.insertBefore(js, fjs);
          }, 1000);
      } else {
          let self = this;
          setTimeout(() => {
            var chatbox = document.getElementById('fb-customer-chat');
            chatbox.setAttribute("page_id", self.id);
            chatbox.setAttribute("attribution", "biz_inbox");

            window.FB.XFBML.parse();
            window.FB.CustomerChat.show(true);
            window.FB.CustomerChat.showDialog();
          }, 500);
      }
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy() {
    if(typeof window != "undefined") {
      if(typeof window.FB != "undefined" && typeof window.FB.CustomerChat != "undefined") {
        window.FB.CustomerChat.hide();
        window.FB.CustomerChat.hideDialog();
      }
    }
  }

}
